import { LastCardKnackLeft } from "@/StyledComponents/TeamStyled";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import Image from "next/image";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Container } from "reactstrap";
import StandingBoy from "../../public/svgs/StandingBoy.svg";
import {
  ServiceInner,
  ServicesBottomIcon,
  ServicesInnerCard,
  ServicesInnerText,
  ServicesItems,
  ServicesItemsInner,
  ServicesLabel,
  ServicesLeft,
  ServicesMain,
  ServicesMiddleCard,
} from "../../StyledComponents/LandingStyled";
import { MobileAppVersatileMapData } from "../DataJson/MobileAppDevelopment/MobileAppDevelopmentMapData";
import LastCardSecIcon from "../svg/LastCardSecIcon";
import IndustryIcon from "../svg/IndustryIcon";
import CeresDrugStoreSvg from "../svg/CeresDrugStoreSvg";
//import StandingBoy from "../../public/svgs/StandingBoy.svg";

export default function LandingServices() {
  const customEase = [0, 0, 0.5, 1];
  return (
    <Container>
      <ServicesMain className="p-b-section !mb-[0px]">
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <ServicesLeft className="HomeServicesLeft">
            <ServiceInner>
              Your industry, our <br /> tailored solutions
            </ServiceInner>
            <ServicesLabel className="pt-3">
              We have a pulse on business trends and market developments to help
              you become an industry leader!
            </ServicesLabel>
          </ServicesLeft>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <ServicesMiddleCard className="home-main-industries">
            {MobileAppVersatileMapData.map((j) => (
              <motion.div
                initial={{ opacity: 0, y: 25 }}
                transition={{
                  duration: 1,
                  ease: customEase,
                  delay: 0.0,
                }}
                whileInView={{ opacity: 1, y: 0 }}
                key={j.id}
              >
                <ServicesItems>
                  <ServicesItemsInner>
                    <Icon icon={j.icon} />
                    <a href={j.link} target="_self">
                      {j.name}
                    </a>
                  </ServicesItemsInner>
                  <Icon
                    icon="heroicons-outline:arrow-sm-up"
                    className="rightCross"
                  />
                </ServicesItems>
              </motion.div>
            ))}
            <ServicesInnerCard className="We-ve-designed-last home-industries p-5">
              <ServicesInnerText>
                “IT Services India delivered a well-functioning mobile app
                allowing easier access to the client’s online store..”
              </ServicesInnerText>
              <LastCardKnackLeft className="justify-between mt-5 pt-3">
                <div className="flex items-center gap-[15px]">
                  <Image
                    src="https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/ted-smernes.webp"
                    alt="Ted Smernes"
                    height={50}
                    width={50}
                    loading="lazy"
                  />
                  <div className="re-auth">
                    {" "}
                    Ted Smernes, <br /> Manager
                  </div>{" "}
                </div>
                {/* <AnupamaIcon /> */}
                <div className="w-[30%]">
                  <CeresDrugStoreSvg />
                </div>
                <div></div>
                <div className="w-[20%]">
                  {" "}
                  <LastCardSecIcon />
                </div>
              </LastCardKnackLeft>
              <ServicesBottomIcon>
                <IndustryIcon />
                {/* <Image src={StandingBoy} width={"100"} height={"100"} className="w-full" alt="stading boy" loading="lazy" /> */}
              </ServicesBottomIcon>
            </ServicesInnerCard>
          </ServicesMiddleCard>
        </AnimationOnScroll>
      </ServicesMain>
    </Container>
  );
}
